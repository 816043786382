import { createClient, cacheExchange, fetchExchange } from "urql";

export const createGraphqlClient = (apiUrl: string, token: string | undefined) => {
  // console.info(`Using API_URL: ${String(apiUrl)}`);
  if (!apiUrl) {
    // temp backup
    apiUrl = String(process.env.SALEOR_API_URL);
  }

  const client = createClient({
    url: apiUrl,
    requestPolicy: "network-only",
    suspense: false,
    fetchOptions: {
      headers: {
        Authorization: `Bearer ${String(token)}`,
      },
    },
    exchanges: [cacheExchange, fetchExchange],
  });
  client.subscribeToDebugTarget?.((e) => console.dir(e, { depth: 999 }));

  return client;
};

// import { authExchange } from "@urql/exchange-auth";
// import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
// import { createClient, makeOperation, cacheExchange, dedupExchange, Operation } from "urql";

// interface AuthState {
//   token: string;
// }

// const addAuthToOperation = ({
//   authState,
//   operation,
// }: {
//   authState?: AuthState | null;
//   operation: Operation<any, any>;
// }) => {
//   if (!authState?.token) {
//     return operation;
//   }

//   const fetchOptions =
//     typeof operation.context.fetchOptions === "function"
//       ? operation.context.fetchOptions()
//       : operation.context.fetchOptions || {};

//   return makeOperation(operation.kind, operation, {
//     ...operation.context,
//     fetchOptions: {
//       ...fetchOptions,
//       headers: {
//         ...fetchOptions.headers,
//         Authorization: `Bearer ${authState.token}`,
//       },
//     },
//   });
// };

// const willAuthError = ({ authState }: { authState?: AuthState | null }) => !authState?.token;

// export const createGraphqlClient = (apiUrl: string, token: string | undefined) => {
//   console.info(`Using API_URL: ${apiUrl}`);
//   return createClient({
//     exchanges: [
//       dedupExchange,
//       cacheExchange,
//       authExchange({
//         getAuth: async () => (token ? { token } : null),
//         willAuthError,
//         addAuthToOperation,
//       }),
//       multipartFetchExchange,
//     ],
//     url: apiUrl,
//   });
// };
